import React from "react";
import aardvarkLogo from "../../assets/images/aardvarkLogo.svg";
const About = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FAFDFF",
        alignItems: "center",
      }}
    >
      {" "}
      <div
        className="divider my-3"
        style={{
          height: "1px",
          backgroundColor: "#A498B0",
          width: "320px",
        }}
      />
      <img src={aardvarkLogo} style={{ marginTop: "50px" }} />
      <div
        style={{
          textAlign: "center",
          fontFamily: "Pretendard",
          fontSize: "16px",
        }}
      >
        <div style={{ fontSize: "20px", marginTop: "40px" }}>
          <span className="text-bold-1">About Us</span> <br />
          <span className="text-bold-1">한큐이그잼</span>을 만든{" "}
          <span className="text-bold-1">아드바크</span>는
          <br />
          이런 회사에요.
        </div>
        <br />
        <span className="text-bold-2"> 아드바크</span>는 서울대
        인공지능연합전공,
        <br />
        서울대 수리과학부, <br />
        서강대 컴퓨터공학과 출신의 <br />
        대치동 과외 선생님 세 명이 모여 창업한 <br />
        <span className="text-bold-2">교육 전문 AI 스타트업입니다.</span>
        <br />
        <br />
        <span className="text-bold-2">국내 최대 입시학원</span>과{" "}
        <span className="text-bold-2"> 컨텐츠 1위 업체</span>영어에 <br />
        AI 솔루션을 납품하고 있고,
        <br /> 2024년 5월 <span className="text-bold-2">중소기업부</span>{" "}
        지원사업
        <br />
        예비창업패키지 선정,
        <br />
        8월 <span className="text-bold-2">국내 1위 초기투자사</span>{" "}
        프라이머로부터
        <br />
        투자를 유치했습니다.
      </div>
    </div>
  );
};

export default About;
