export const preMaterialImages = {
  exam: {
    src: require("./mainLogo.png"),
    name: "25년도 9월 모의고사",
  },
  exam: {
    src: require("./mainLogo.png"),
    name: "25년도 9월 모의고사",
  },

  handout: {
    src: require("./mainLogo.png"),
    name: "학교 유인물",
  },
  textbook: {
    src: require("./mainLogo.png"),
    name: "교과서",
  },
};
export const preMaterialImagesArray = [
  {
    src: preMaterialImages.exam.src,
    name: preMaterialImages.exam.name,
  },
  {
    src: preMaterialImages.handout.src,
    name: preMaterialImages.handout.name,
  },
  {
    src: preMaterialImages.textbook.src,
    name: preMaterialImages.textbook.name,
  },
];
