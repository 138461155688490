import React, { useRef } from "react";
import { Modal } from "react-bootstrap";
import cameraImg from "../../assets/images/cameraImg.svg";
import uploadImg from "../../assets/images/uploadImg.svg";

const AddImageModal = ({ show, handleClose, images, setImages }) => {
  const fileInputRef = useRef(null);
  const captureInputRef = useRef(null);

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    const newImages = files.map((file) => ({
      file,
      url: URL.createObjectURL(file),
    }));
    setImages((prevImages) => [...prevImages, ...newImages]);
    event.target.value = null;
    handleClose();
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleCaptureButtonClick = () => {
    captureInputRef.current.click();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      contentClassName="custom-modal-content"
      dialogClassName="custom-modal-dialog"
    >
      <div className="custom-modal-body d-flex flex-column justify-content-center align-items-center">
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          multiple
          onChange={handleImageUpload}
          style={{ display: "none" }}
        />
        <input
          ref={captureInputRef}
          type="file"
          accept="image/*"
          capture="environment"
          onChange={handleImageUpload}
          style={{ display: "none" }}
        />
        <CaptureAndUploadButton
          handleButtonClick={handleCaptureButtonClick}
          label={cameraImg}
        />
        <CaptureAndUploadButton
          handleButtonClick={handleUploadButtonClick}
          label={uploadImg}
        />
      </div>
    </Modal>
  );
};

function CaptureAndUploadButton({ handleButtonClick, className, label }) {
  return (
    <span>
      <img
        src={label}
        onClick={handleButtonClick}
        style={{ cursor: "pointer" }}
      />
    </span>
  );
}

export default AddImageModal;
