import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import ResultPage from "./pages/ResultPage";
import MockLoading from "./components/common/MockLoading";
import DemoResult from "./pages/DemoResult";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/result" element={<DemoResult />} />
        <Route path="/loading" element={<MockLoading />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
