import React, { useState } from "react";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import MaterialInfo from "../components/DemoResult/MaterialInfo";
import About from "../components/DemoResult/About";
import Btn from "../assets/images/visitcafeBtn.svg";
import { useLocation } from "react-router-dom";
const DemoResult = () => {
  const location = useLocation();
  const { images, inputTypeQuantity } = location.state || {};
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FAFDFF",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Header />
      <main style={{ flex: "1" }}>
        <MaterialInfo images={images} inputTypeQuantity={inputTypeQuantity} />
        <About />
      </main>
      <a
        href="https://cafe.naver.com/aardvark"
        target="_blank"
        style={{
          position: "fixed",
          bottom: "0",
          width: "390px",
          height: "115px",
          background:
            "linear-gradient(180deg, rgba(249, 252, 255, 0.00) 0%, #F9FCFF 51.5%)",
        }}
      >
        <img src={Btn} style={{ width: "100%", height: "100%" }} />
      </a>

      <Footer />
      <div style={{ marginBottom: "100px" }} />
    </div>
  );
};

export default DemoResult;
