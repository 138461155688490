import React, { useState, useEffect, useRef } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import axios from "axios";
import SliderInterface from "./SliderInterface";
import { API_BASE_URL } from "./../../utils/API_BASE_URL";
import AddImageModal from "./AddImageModal";
import addImageIcon from "../../assets/images/galleryadd.svg";
import { useNavigate } from "react-router-dom";
import PreMaterial from "./PreMaterial";
import closeIcon from "../../assets/images/close.svg";
import makeBtn from "../../assets/images/makeMaterial.svg";
import closeTri from "../../assets/images/triangleReversed.svg";
import openTri from "../../assets/images/triangle.svg";
import resetImgIcon from "../../assets/images/reset.svg";
import MockLoading from "../common/MockLoading";
const ItemType = {
  IMAGE: "image",
};

function ImageUpload() {
  const [images, setImages] = useState([]);

  //문제 유형
  const [inputTypeQuantity, setInputTypeQuantity] = useState({
    binkan: 0,
    sunseo: 0,
    sabib: 0,
    hamui: 0,
    juje: 0,
    jemok: 0,
    yoji: 0,
    jujang: 0,
    ubub: 0,
    uhui: 0,
  });
  const [loading, setLoading] = useState(false);
  const [jwt, setJwt] = useState("");
  const [createdBase64File, setCreatedBase64File] = useState(null);
  const [createdBase64FileName, setCreatedBase64FileName] = useState("");
  const [isToggleOpen, setIsToggleOpen] = useState(true);

  const handleToggleClick = () => {
    setIsToggleOpen(!isToggleOpen);
  };
  useEffect(() => {
    if (jwt) {
      sessionStorage.setItem("jwt", jwt);
    }
  }, [jwt]);
  useEffect(() => {
    setInputTypeQuantity((prev) => {
      const updatedQuantity = {};
      for (let type in prev) {
        updatedQuantity[type] = Math.min(images.length * 2, 0);
      }
      return updatedQuantity;
    });
  }, [images]);
  // useEffect(() => {
  //   if (createdBase64File) {
  //     navigate("/result", {
  //       state: {
  //         createdBase64File,
  //         fileName: createdBase64FileName,
  //         images,
  //         inputTypeQuantity,
  //       },
  //     });
  //   }
  // }, [
  //   createdBase64File,
  //   createdBase64FileName,
  //   images,
  //   inputTypeQuantity,
  //   navigate,
  // ]);

  return (
    <div className="d-flex flex-column align-items-center">
      {" "}
      <DndProvider backend={HTML5Backend}>
        <ImagePreviewInterface images={images} setImages={setImages} />
        {images.length > 0 && (
          <>
            <ConfirmButtonContainer
              inputTypeQuantity={inputTypeQuantity}
              images={images}
              setInputTypeQuantity={inputTypeQuantity}
              setCreatedBase64File={setCreatedBase64File}
              setCreatedBase64FileName={setCreatedBase64FileName}
              setLoading={setLoading}
            />{" "}
            <div className="toggle-container">
              <div
                style={{
                  fontFamily: "Pretendard",
                  fontSize: "10px",
                  fontWeight: "300",
                  margin: "0px 10px 20px 230px",
                  cursor: "pointer",
                }}
                onClick={handleToggleClick}
                className="toggle-button"
              >
                {isToggleOpen ? (
                  <>
                    문제유형 세부설정 <img src={openTri} />
                  </>
                ) : (
                  <>
                    문제유형 세부설정
                    <img src={closeTri} />
                  </>
                )}
              </div>
            </div>
            {isToggleOpen && (
              <>
                <SliderInterface
                  inputTypeQuantity={inputTypeQuantity}
                  setInputTypeQuantity={setInputTypeQuantity}
                />
              </>
            )}
          </>
        )}
        {loading && <MockLoading />}
      </DndProvider>
    </div>
  );
}

export default ImageUpload;

function ImagePreviewInterface({ images, setImages }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const [paddingLeft, setPaddingLeft] = useState(0);

  useEffect(() => {
    const calculatePaddingLeft = () => {
      const paddingPerImage = 75;
      setPaddingLeft((images.length - 3) * paddingPerImage);
    };

    calculatePaddingLeft();
  }, [images]);
  return (
    <div style={{ margin: "0px", position: "relative" }}>
      {" "}
      <div>
        {images.length > 0 ? (
          <>
            <div
              style={{
                fontFamily: "Pretendard",
                fontSize: "14px",
                fontWeight: "300",
              }}
            >
              자료를 만들 이미지를 확인해 보세요.
              <img
                src={resetImgIcon}
                style={{ margin: "5px", cursor: "pointer" }}
                onClick={() => setImages([])}
              />
            </div>
            <div
              className="scrollable-container d-flex flex-wrap justify-content-center position-relative"
              style={{
                width: "318px",
                height: "160px",
                borderRadius: "25px",
                border: "1px solid #251184",
                backgroundColor: "#F5F5F5",
                overflowX: "auto",
                whiteSpace: "nowrap",
                flexWrap: "nowrap",
                paddingLeft: `${paddingLeft}px`,
                paddingBottom: "15px",
              }}
            >
              <ImagePreview images={images} setImages={setImages} />{" "}
            </div>

            <button
              style={{
                position: "absolute",
                bottom: "10px",
                right: "10px",
                width: "90px",
                height: "30px",
                borderRadius: "15px",
                border: "none",
                backgroundColor: "#5134DC",
                color: "#ffffff",
                zIndex: 1,
                fontFamily: "Pretendard",
                fontSize: "10px",
                fontWeight: "400",
              }}
              onClick={handleOpenModal}
            >
              이미지추가
            </button>
          </>
        ) : (
          <div className="d-flex flex-column align-items-center">
            <div
              className="text"
              style={{ marginTop: "10px", textAlign: "center" }}
            >
              <div
                style={{
                  fontFamily: "Pretendard",
                  fontSize: "16px",
                  fontWeight: "700",
                  margin: "5px",
                }}
              >
                수업교재를 만들 이미지를 업로드해주세요.{" "}
              </div>
              <div
                style={{
                  fontFamily: "Pretendard",
                  fontSize: "14px",
                  fontWeight: "300",
                }}
              >
                페이지 순서대로 올려주세요.
              </div>
            </div>
            <div
              className="d-flex flex-column justify-content-center align-items-center mt-4"
              style={{
                width: "318px",
                height: "160px",
                borderRadius: "25px",
                border: "1px dashed #4A4A4A",
                backgroundColor: "#F5F5F5",
                cursor: "pointer",
                fontFamily: "Pretendard",
                fontSize: "12px",
                fontWeight: "400",
              }}
              onClick={handleOpenModal}
            >
              <img src={addImageIcon} style={{ margin: "15px" }} />
              <span>클릭해서 이미지 촬영/선택하기</span>
            </div>
            <PreMaterial setImages={setImages} />
          </div>
        )}
      </div>
      {isModalOpen && (
        <AddImageModal
          show={isModalOpen}
          handleClose={handleCloseModal}
          images={images}
          setImages={setImages}
        />
      )}
    </div>
  );
}

function ImagePreview({ images, setImages }) {
  const moveImage = (dragIndex, hoverIndex) => {
    const dragImage = images[dragIndex];
    const updatedImages = [...images];
    updatedImages.splice(dragIndex, 1);
    updatedImages.splice(hoverIndex, 0, dragImage);
    setImages(updatedImages);
  };

  return (
    <div
      className="d-flex"
      style={{
        alignItems: "center",
        height: "100%",
      }}
    >
      {" "}
      {images.map((image, index) => (
        <ImageWithDeleteButton
          key={index}
          image={image}
          index={index}
          moveImage={moveImage}
          setImages={setImages}
        />
      ))}
    </div>
  );
}

function ImageWithDeleteButton({ image, index, moveImage, setImages }) {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: ItemType.IMAGE,
    hover(item) {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) return;
      moveImage(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType.IMAGE,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const handleImageDelete = () => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  return (
    <div
      ref={ref}
      className="image-container m-2"
      style={{
        width: "70px",
        height: "85px",
        position: "relative",
        opacity: isDragging ? 0.5 : 1,
        cursor: "pointer",
      }}
    >
      <img
        src={image.url}
        alt={`upload-${index}`}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />
      <DeleteButton
        className="position-absolute top-0 end-0"
        handleClick={handleImageDelete}
      />
    </div>
  );
}

function DeleteButton({ className, handleClick }) {
  return (
    <span className={className}>
      <img
        src={closeIcon}
        onClick={handleClick}
        style={{
          width: "25px",
          height: "25px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 0,
          fontSize: "1rem",
        }}
      />
    </span>
  );
}

function ConfirmButtonContainer({
  images,
  setJson,
  inputTypeQuantity,
  setCreatedBase64File,
  setCreatedBase64FileName,
  setLoading,
}) {
  return (
    <div>
      <ConfirmButton
        images={images}
        setJson={setJson}
        inputTypeQuantity={inputTypeQuantity}
        setCreatedBase64File={setCreatedBase64File}
        setCreatedBase64FileName={setCreatedBase64FileName}
        setLoading={setLoading}
      />
    </div>
  );
}
function ConfirmButton({
  images,
  inputTypeQuantity,
  setCreatedBase64File,
  setCreatedBase64FileName,
  setLoading,
}) {
  const jwt = sessionStorage.getItem("jwt");
  const navigate = useNavigate();

  const handleClick = async () => {
    if (!jwt) {
      alert("JWT가 없습니다. 로그인 후 다시 시도해주세요.");
      return;
    }
    if (images.length < 1) {
      alert("파일이 없습니다!");
      return;
    }

    if (window.confirm("파일을 확정하시겠습니까?")) {
      const userNameInput = window.prompt("자료를 받으실 이메일을 입력하세요.");
      if (!userNameInput) {
        alert("이메일이 입력되지 않았습니다.");
        return;
      }

      setLoading(true);
      const formData = new FormData();
      images.forEach((image) => formData.append("files", image.file));
      formData.append("type_quantity_dict", JSON.stringify(inputTypeQuantity));
      formData.append("num_images", images.length);
      formData.append("num_problems", calculateNumProblems(inputTypeQuantity));
      formData.append("jwt", jwt);
      formData.append("demo_user_email", userNameInput);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${jwt}`,
        },
      };

      try {
        // alert("이미지가 성공적으로 업로드되었습니다!");
        // alert("자료를 만들겠습니다. 잠시만 기다려주세요!");

        // const base64EncodedFile = response.data.data.file;

        // let userFileNameInput = window.prompt("파일이름을 입력하세요!");
        // if (!userFileNameInput) userFileNameInput = "default_filename";
        // setCreatedBase64FileName(`${userFileNameInput}.hml`);
        // setCreatedBase64File(base64EncodedFile);

        // await saveBase64File(base64EncodedFile, `${userFileNameInput}.hml`);

        // alert("자료를 만들었습니다! 버튼을 눌러 다운로드해보세요!");
        // } catch (error) {
        //   handleError(error);
        // } finally {
        //   setLoading(false);
        // }
        axios.post(`${API_BASE_URL}/hanq/exam`, formData, config);

        const timer = setTimeout(() => {
          navigate("/result", {
            state: {
              images,
              inputTypeQuantity,
            },
          });
        }, 1000);

        return () => clearTimeout(timer);
      } catch (error) {
        handleError(error);
        setLoading(false);
      } finally {
      }
    }
  };

  // const saveBase64File = async (base64EncodedFile, fileName) => {
  //   try {
  //     await axios.post(
  //       `${API_BASE_URL}/hanq/save`,
  //       {
  //         jwt: jwt,
  //         target_file_base64: base64EncodedFile,
  //         target_file_name: fileName,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${jwt}`,
  //         },
  //       }
  //     );
  //   } catch (error) {
  //     handleError(error);
  //     throw error;
  //   }
  // };

  const handleError = (error) => {
    if (error.response) {
      console.error("Error:", error.response.data);
      alert(`파일 업로드에 실패했습니다: ${error.response.data}`);
    } else if (error.request) {
      console.error("Error:", error.request);
      alert("서버로부터 응답이 없습니다. 서버를 확인해주세요.");
    } else {
      console.error("Error:", error.message);
      alert(`알 수 없는 오류가 발생했습니다: ${error.message}`);
    }
  };

  const calculateNumProblems = (inputTypeQuantity) => {
    return Object.values(inputTypeQuantity).reduce(
      (total, quantity) => total + quantity,
      0
    );
  };

  return (
    <div
      className="d-flex justify-content-center"
      style={{ marginTop: "10px", marginBottom: "15px" }}
    >
      {" "}
      <img onClick={handleClick} style={{ cursor: "pointer" }} src={makeBtn} />
    </div>
  );
}
