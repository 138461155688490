import React from "react";
import mainLogo from "../../assets/images/mainLogo.svg";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#FAFDFF",
        paddingTop: "80px",
        cursor: "pointer",
      }}
      onClick={() => navigate("/")}
    >
      <img className="w-[253px] h-[142px]" src={mainLogo} alt="Main Logo" />
      <div
        className="divider"
        style={{
          height: "1px",
          backgroundColor: "#A498B0",
          width: "320px",
          marginBottom: "30px",
          marginTop: "23px",
        }}
      ></div>
    </div>
  );
};

export default Header;
