import React from "react";
import Q from "../../assets/images/Q.svg";
const MockLoading = () => {
  return (
    <div
      className="loading-overlay d-flex flex-column justify-content-center align-items-center position-fixed top-0 start-0 w-100 h-100 bg-light"
      style={{ zIndex: 1050, backgroundColor: "#FAFDFF", minHeight: "100vh" }}
    >
      <img src={Q} style={{ width: "110px" }} />
      <div
        style={{
          fontFamily: "Pretendard",
          fontSize: "16px",
          fontWeight: "300",
          textAlign: "center",
        }}
      >
        사진을 업로드하고 있어요.{" "}
      </div>
      <div
        style={{
          marginTop: "55px",
          fontFamily: "Pretendard",
          fontSize: "14px",
          fontWeight: "400",
          textAlign: "center",
        }}
      >
        화면을 나가셔도 괜찮아요. <br />
        자료가 완성되면 메일로 알려드려요!{" "}
      </div>
    </div>
  );
};

export default MockLoading;
