import React from "react";

const Footer = () => {
  return (
    <div style={{ margin: "20px" }}>
      <div
        className="divider my-3"
        style={{
          height: "1px",
          backgroundColor: "#A498B0",
          width: "320px",
          marginTop: "30px",
          marginBottom: "15px",
        }}
      />
      <div
        style={{
          fontFamily: "Pretendard",
          fontSize: "10px",
          fontWeight: "300",
          textAlign: "center",
        }}
      >
        {/* <div>ⓒ 2024. Aardvark. All rights reserved.</div>
        <div>Contact | team@aardvark.co.kr</div> */}
        <div>상호명 아드바크 | 사업자등록번호 595-30-01391</div>
        <div>서울특별시 관악구 관악로 1 IBK커뮤니케이션관 1층 5호</div>
      </div>
    </div>
  );
};

export default Footer;
