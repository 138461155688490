import React from "react";

function SliderInterface({ inputTypeQuantity, setInputTypeQuantity }) {
  //문제 유형
  const labels = {
    binkan: "빈칸",
    sunseo: "순서",
    sabib: "삽입",
    hamui: "함의",
    juje: "주제",
    jemok: "제목",
    yoji: "요지",
    jujang: "주장",
    ubub: "어법",
    uhui: "어휘",
  };

  const handleSliderChange = (label, newValue) => {
    setInputTypeQuantity((prev) => ({
      ...prev,
      [label]: newValue,
    }));
  };

  return (
    <div>
      <div
        style={{
          fontFamily: "Pretendard",
          fontSize: "10px",
          fontWeight: "400",
        }}
      >
      <strong>(문제생성 기능은 준비중입니다. 구문분석, 암기자료를 만들어보세요!)</strong>
      </div>
      <br />
      <div className="slider-container ">
        {Object.keys(labels).map((label) => (
          <div className="slider-item " key={label}>
            <label
              style={{
                fontFamily: "Pretendard",
                fontSize: "10.5px",
                fontWeight: "400",
              }}
            >
              {labels[label]}: {inputTypeQuantity[label]}
            </label>
            <input
              type="range"
              className="custom-slider"
              min="0"
              max="5"
              value={inputTypeQuantity[label]}
              onChange={(e) =>
                handleSliderChange(label, parseInt(e.target.value))
              }
              disabled={true}
            />
          </div>
        ))}{" "}
      </div>
      <br />
      <div className="d-flex flex-column align-items-center">
        <div
          style={{
            textAlign: "center",
            color: "rgba(0, 0, 0, 0.50)",
            fontFamily: "Pretendard",
            fontSize: "10px",
            fontWeight: "400",
          }}
        >
          100문항 이상 생성을 원하신다면 <br />
          아래 오픈카톡에 ‘이메일’과 ‘유형별 문항 수’를 남겨주세요.
        </div>{" "}
        <a
          href="https://open.kakao.com/o/sgJPfLLg"
          target="_blank"
          style={{
            textAlign: "center",
            fontFamily: "Pretendard",
            fontSize: "10px",
            fontWeight: "700",
            color: "#000000",
          }}
        >
          [오픈카톡 바로가기]
        </a>
      </div>
    </div>
  );
}

export default SliderInterface;
