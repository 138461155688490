import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../utils/API_BASE_URL";
import axios from "axios";
const AutoLogin = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const autoLogin = async () => {
      try {
        const body = {
          user_code: "hanq_exam_demo",
        };

        const response = await axios.post(`${API_BASE_URL}/auth/login`, body, {
          headers: {
            "Content-Type": "application/json",
            Authorization: sessionStorage.getItem("jwt")
              ? `Bearer ${sessionStorage.getItem("jwt")}`
              : "",
          },
        });

        if (response.data.data && response.data.data.jwt) {
          sessionStorage.setItem("jwt", response.data.data.jwt);
          sessionStorage.setItem("user_id", response.data.data.user_id);
        } else {
          console.error("로그인 실패:", response.data.message);
        }
      } catch (error) {
        console.error("자동 로그인 실패:", error);
      }
    };

    autoLogin();
  }, [navigate]);

  return null;
};

export default AutoLogin;
