import React from "react";
import materialImg1 from "../../assets/images/본문분석.png";
import materialImg2 from "../../assets/images/본문암기워크시트.png";
import materialImg3 from "../../assets/images/연습문제.svg";
import materialImg4 from "../../assets/images/교재.svg";

const MaterialInfo = ({ images, inputTypeQuantity }) => {
  const totalProblemQuantity = Object.values(inputTypeQuantity).reduce(
    (total, quantity) => total + quantity,
    0
  );

  return (
    <div style={{ fontFamily: "Pretendard", textAlign: "center" }}>
      <div className="text-1" style={{ marginTop: "66px" }}>
        업로드 완료!{" "}
      </div>
      <div
        style={{
          fontSize: "16px",
          fontWeight: "700",
          marginTop: "40px",
        }}
      >
        10분 내에 <span style={{ color: "#5134DC" }}>이메일로</span>
        <br /> 교재 샘플을 보내드려요. <br />
        <span style={{ fontWeight: "400" }}>(무료)</span>
      </div>
      <div className="text-1" style={{ marginTop: "56px" }}>
        교재 구성 미리보기{" "}
      </div>
      <div
        style={{
          fontSize: "16px",
          fontWeight: "700",
          marginTop: "17px",
        }}
      >
        <span
          style={{
            color: "#5134DC",
            fontSize: "16px",
            fontWeight: "700",
          }}
        >
          본문분석 + 암기 워크시트 + (연습문제)
        </span>
        <div> 3종 구성 </div>
        <br /> 이거 하나면 수업 준비, 숙제 자료까지 끝!
        <br /><br />                 
          <strong>연습문제 출시알림 신청<br/></strong> : <small><a href ="https://dgs4jlpazyy.typeform.com/to/oV5GWWnL" target="_blank">https://dgs4jlpazyy.typeform.com/to/oV5GWWnL</a></small>
          {/* <small>(연습문제는 준비중입니다)</small> */}                  
      </div>
      <img
        src={materialImg1}
        style={{
          marginTop: "56px",
          width: "327px",
          height: "auto",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        }}
      />
      <div className="text-1" style={{ marginTop: "19px" }}>
        본문 분석{" "}
      </div>{" "}
      <div
        style={{
          fontSize: "20px",
          fontWeight: "400",
        }}
      >
        총 {3 * images.length} 페이지 이상{" "}
      </div>
      <img
        src={materialImg2}
        style={{
          marginTop: "56px",
          width: "327px",
          height: "auto",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        }}
      />
      <div className="text-1" style={{ marginTop: "19px" }}>
        본문암기 워크시트{" "}
      </div>{" "}
      <div
        style={{
          fontSize: "20px",
          fontWeight: "400",
        }}
      >
        총 {2 * images.length} 페이지 이상{" "}
      </div>
      <img src={materialImg3} style={{ marginTop: "56px" }} />
      <div className="text-1" style={{ marginTop: "19px" }}>
        연습문제{" "}
      </div>{" "}
      <div
        style={{
          fontSize: "20px",
          fontWeight: "400",
        }}
      >
        총 {totalProblemQuantity} 문제{" "}
      </div>
      <img
        src={materialImg4}
        style={{ marginTop: "56px", marginBottom: "27px" }}
      />
      <div
        style={{
          fontSize: "16px",
          fontWeight: "400",
        }}
      >
        샘플 파일이 아닌 교재 전체의 한글 파일은
        <br />
        유료로 제공하고 있습니다.{" "}
      </div>
      <div
        style={{
          fontSize: "16px",
          fontWeight: "400",
          marginTop: "80px",
          marginBottom: "20px",
        }}
      >
        메일로 보내드린 샘플이 마음에 드신다면?
      </div>
      <div className="text-1">교재 가격 안내</div>
      <div
        style={{
          fontSize: "16px",
          fontWeight: "700",
          marginTop: "20px",
        }}
      >
        업로드하신 페이지 당 <span style={{ color: "#5134DC" }}>2,000원</span>
        <br /> 문제 당 <span style={{ color: "#5134DC" }}>400원</span>
        <br />{" "}
        <span style={{ color: "#5134DC" }}>
          = {2000 * images.length + 400 * totalProblemQuantity}원
        </span>
      </div>
      <div
        style={{
          fontSize: "16px",
          fontWeight: "400",
          marginTop: "20px",
          marginBottom: "60px",
        }}
      >
        결제 후 24시간 내 교재 한글파일이 발송됩니다. <br />
        결제 방법은 메일을 참조해주세요.
      </div>
      <div style={{ fontSize: "20px", fontWeight: "400" }}>
        <span style={{ fontWeight: "700" }}>교재 오류가 걱정되시나요?</span>
        <br />
        교재 오류 발견 시 <span style={{ fontWeight: "700" }}>즉시 환불</span>
        해드립니다.
      </div>
      <div
        style={{
          fontSize: "14px",
          fontWeight: "400",
          marginTop: "20px",
          marginBottom: "60px",
        }}
      >
        오류 문의: team@aardvark.co.kr
      </div>
    </div>
  );
};

export default MaterialInfo;
