import React, { useState } from "react";
import Header from "../components/common/Header";
import ImageUpload from "../components/home/ImageUpload";
import Footer from "../components/common/Footer";
import AutoLogin from "../components/home/AutoLogin";

const Home = () => {
  const [jwt, setJwt] = useState(null);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FAFDFF",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <AutoLogin setJwt={setJwt} />
      <Header />

      <main style={{ display: "flex", flex: "1", flexDirection: "column" }}>
        <ImageUpload jwt={jwt} />
      </main>
      <Footer />
    </div>
  );
};

export default Home;
