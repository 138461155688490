import React, { useState } from "react";
import preMaterialImage1 from "../../assets/images/prematerial1.svg";
import preMaterialImage2 from "../../assets/images/prematerial2.jpg";
import preMaterialImage3 from "../../assets/images/prematerial3.svg";
import { preMaterialImages } from "./../../assets/images/preMaterialImages";

const PreMaterial = ({ setImages }) => {
  const handleImageClick = (imageKey) => {
    const imageFile = preMaterialImages[imageKey];
    if (!imageFile) {
      console.error(`Image with key ${imageKey} does not exist.`);
      return;
    }
    const { src, name } = imageFile;

    setImages((prevImages) => [...prevImages, { url: src, name: name }]);
  };
  return (
    <div className="mt-4">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          fontFamily: "Pretendard",
          fontSize: "14px",
          fontWeight: "400",
          textAlign: "left",
          marginLeft: "20px",
        }}
      >
        또는 미리 준비된 이미지로 만들어보세요!
      </div>
      <div
        className="d-flex flex-row align-items-center "
        style={{ margin: "0px 10px" }}
      >
        <div className="pre-material">
          <a
            href="https://slashpage.com/hanqexam-examples/ndvwx728n86d923z6jpg"
            target="_blank"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            {" "}
            <img
              src={preMaterialImage1}
              style={{ margin: "10px 0px" }}
              // onClick={() => handleImageClick("exam")}
            />
            <div>25년도 9월 모의고사</div>
          </a>
        </div>
        <div className="pre-material">
          <a
            href="https://slashpage.com/hanqexam-examples/n8pw9x2zpqyvn2g7yrqv"
            target="_blank"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <img
              src={preMaterialImage2}
              style={{
                margin: "10px 0px",
                width: "56px",
                height: "76px",
                boxShadow: "4px 4px 2px -2px rgba(0, 0, 0, 0.25)",
              }}
              // onClick={() => handleImageClick("handout")}
            />
            <div>부교재</div>
          </a>
        </div>
        <div className="pre-material">
          <a
            href="https://slashpage.com/hanqexam-examples/93nzyxmd4k1n5mwk6r45"
            target="_blank"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            {" "}
            <img
              src={preMaterialImage3}
              style={{ margin: "10px 0px" }}
              // onClick={() => handleImageClick("textbook")}
            />
            <div>유인물</div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default PreMaterial;
